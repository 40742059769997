/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;800&display=swap');

:root {
  --vrib-color: #6666e1;
  --vrib-grey: #484554;
  --vrib-light: #ACA9BB;
  --vrib-red: #DA353F;
  --vrib-pink: #FF716F;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
}

input {
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-elements {
  max-height: 100px;
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
  max-width: 1320px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  transition: all 150ms ease-in-out;
}

.navbar-elements.hidden-navbar {
  transform: translateY(-100%);
}

.navbar-logo {
  height: 60px;
  margin: 10px 0;
}

.navbar-left {
  padding: 1rem 0;
}

.navbar-center {
  justify-self: flex-start;
  margin-left: 3rem;
}

.navbar-right {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  position: relative;
}

.navbar-center > *:not(:last-child) {
  margin-right: 3rem;
}

.navbar-center a, .navbar-center span, .host-navigation a, .host-navigation span {
  color: #333;
  text-decoration: none;
  transition: all 100ms ease-in;
  padding: 0 3px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  z-index: 999;
}

.navbar-center a::after, .navbar-center span::after, .host-navigation a::after, .host-navigation span::after, .user-login.anonym::after {
  content: '';
  display: inline-flex;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  height: 1px;
  width: 0;
  background-color: var(--vrib-pink);
  transition: all 200ms ease-out;
}


.navbar-center a:hover::after, .navbar-center span:hover::after, .host-navigation a:hover::after, .host-navigation span:hover::after, .user-login.anonym:hover::after {
  width: 100%;
}

.main-content {
  /*margin-top: 5rem;*/
  margin-top: 0;
}

.main-navbar {
  background-color: white;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.35s ease-out;
  transform: translateY(0);
  opacity: 1;
}

.main-navbar.sticky-navbar {
  transition: all 0.35s ease-out;
}

.main-navbar.top-navbar {
  background-color: transparent;
  box-shadow: none;
  position: absolute;
}

.main-navbar.top-navbar .navbar-center a,.main-navbar.top-navbar .navbar-center span {
  color: white;
  font-size: 1.1rem;
}

.main-navbar.top-navbar .navbar-center .dropdown-destination {
  border-radius: 1rem;
  padding-top: 1rem;
  transition: all 0.35s ease-out;
  opacity: 0;
}

.main-navbar.top-navbar .navbar-center span:first-of-type:hover .dropdown-destination {
  opacity: 1;
  transition: all 0.35s ease-out;
}

.main-navbar.top-navbar .navbar-center .dropdown-destination a {
  color: #333;
}

.navbar-right .user-login {
  cursor: pointer;
  padding: 1.34rem 0;
}

.navbar-right .user-login.anonym {
  padding: 0;
  position: relative;
}

.user-loggedin {
  cursor: pointer;
  transition: all 150ms ease-in-out;
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.user-loggedin:hover {
  box-shadow: 3px 6px 9px #00000015;
}

.host-navigation {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1320px;
  margin: 3rem auto;
  grid-gap: 30px;
}

.main-frame {
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.main-frame h1 {
  font-size: 2.25rem;
  color: #484848;
  line-height: 1.3;
  margin: 0px 15px 15px 0px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.single-news {
  background: white;
  box-shadow: 3px 3px 6px 2px #00000015;
  border-radius: 1rem;
  overflow: hidden;
}

.news-content {
  padding: 1rem;
  margin-bottom: 1rem;
}

.news-title {
  font-size: 18px;
  line-height: 1.25;
  color: var(--vrib-color);
  margin-bottom: 0.5rem;
}

.news-date {
  border-top: 2px solid var(--vrib-color);
  display: inline-block;
  padding-top: 0.5rem;
}

.news-paginate {
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-paginate a {
  text-decoration: none;
}

.news-paginate p {
  cursor: pointer;
  transition: all 200ms ease-in-out;
  padding: 0.25rem 0.5rem;
}

.news-paginate p:hover {
  box-shadow: 0 -3rem inset var(--vrib-color);
  color: white;
}

.pagination-active {
  border-bottom: 2px solid var(--vrib-color);
  padding-bottom: 0.25rem;
  font-weight: bold;
}

.news-paginate svg.paginate {
  font-size: 1.5rem;
}

.news-image {
  max-width: 420px;
  height: 250px;
  margin-bottom: 1rem;
}

.news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-image-wrapper {
  position: relative;
  height: 280px;
  overflow: hidden;
}

.single-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-card {
  box-shadow: 1px 3px 15px rgba(0,0,0,.1);
  background: white;
  height: 100%;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.single-card-price {
  bottom: 10px;
  left: 10px;
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  grid-gap: 1.25rem;
  margin-top: 1rem;
}

.single-card-location .single-card-price p {
  font-size: 1rem;
  font-weight: 600;
}

.single-card-location .single-card-price p:not(:last-of-type)::after {
  display: none;
}

.single-card-description {
  padding: 0.5rem 1.5rem;
  margin: 15px 0;
}

.single-card-description h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.25;
}

.single-card-location span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.single-card-location p {
  text-decoration: none;
  color: #333;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.single-card-location p:not(:last-of-type)::after {
  content: '';
  display: block;
  width: 2px;
  background-color: var(--vrib-light);
  height: 30px;
  margin-left: 0.625rem;
}

.single-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.3) 100%);
}

.single-image-overlay a {
  display: block;
  width: 100%;
  height: 100%;
}

#login-modal {
  position: fixed;
  background: white;
  padding: 3rem;
  border-radius: 10px;
  justify-self: center;
  align-self: center;
  transition: all 100ms ease-in-out;
}

.login-divider {
  display: flex;
  align-items: center;
}

.login-line {
  flex: 1;
  border-bottom: 2px solid #ddd;
}

.login-circle {
  background: white;
  border-radius: 50%;
  border: 1px solid #ddd;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ddd;
}

.local-login {
  position: relative;
}

.local-error {
  position: absolute;
  bottom: 100%;
  padding: 0.5rem;
  background: pink;
  font-size: 0.8rem;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: 0.5rem;
}

.modal-back {
  background-color: rgba(0,0,0,.75);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.header-bounder {
  width: 100%;
  position: relative;
}

.header-holder {
  overflow-x: hidden;
  position: relative;
}

.header-context {
  width: 200%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 600px;
  transition: all 750ms cubic-bezier(.79,.14,.15,.86);
}

.header-context {
  display: block;
  width: 100%;
}

.header-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fluid-context {
  position: absolute;
  z-index: 9;
}

.header-back {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.header-back h1 {
  font-size: 3.75rem;
  font-weight: 800;
  line-height: 1.175;
  color: white;
  margin-top: 3rem;
}

.header-back p {
  color: white;
  font-size: 1.875rem;
  margin-top: 3rem;
}

.header-back button, .submit-button, .vrib-button {
  background-color: var(--vrib-color);
  color: white;
  border: none;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  border-radius: 1rem;
  cursor: pointer;
  text-align: center;
  letter-spacing: 1px;
  transition: all 100ms ease-in;
}

.header-back button {
  padding: 1rem;
  margin-top: 3rem;
}

.header-back button:hover, .submit-button:hover, .vrib-button:hover {
  background-color: #a1a1db;
}

.search-bar {
  max-width: 1230px;
  margin: 0 auto;
}

.search-bar form {
  display: flex;
  justify-content: space-between;
}

.search-bar form > div {
  position: relative;
  background: white;
  border: 1px solid #333;
  border-radius: 2px;
  cursor: pointer;
}

.search-bar form input, .search-bar form select  {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  padding-left: 2rem;
}

.search-bar form input:focus-visible {
  outline: none;
}

.search-bar .input-icon {
  color: var(--vrib-color);
  position: absolute;
  top: 10px;
  left: 5px;
  transform: scale(1.25);
}

.search-bar .submit-button {
  padding: 0 2rem;
}

.search-bar {
  position: absolute;
  bottom: 5rem;
  background: rgba(255,255,255,.8);
  padding: 2rem 5rem;
  left: 0;
  right: 0;
  z-index: 10;
}

.date-input {
  position: relative;
}

.date-popup {
  position: absolute;
  background-color: white;
  top: calc(100% + 10px);
  border: 1px solid #333;
  border-radius: 5px;
}

.footer {
  background-color: var(--vrib-color);
}

.footer-elements {
  max-width: 1320px;
  margin: 0 auto;
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.footer-elements h2 {
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}

.footer-elements p, .footer-elements a {
  color: white;
  line-height: 1.25;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.footer-elements a {
  margin-left: 0.75rem;
  transition: all 200ms ease-in;
}

.footer-elements a:hover {
  box-shadow: 0 -20px inset white;
  color: var(--vrib-color);
}

.footer-elements p svg {
  transform: scale(1.1) translateY(3px);
}

.footer-left img {
  width: 37%;
}

.footer .footer-copyright {
  margin-top: 3rem;
}

.user-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-details svg {
  color: #aaa;
  margin-left: 0.5rem;
}

.avatar-holder {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  background-color: grey;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.user-dropdown {
  /*transition: all 200ms ease-in;
  transform: rotateX(90deg);
  transform-origin: top;*/
  padding: 2rem;
  position: absolute;
  z-index: 99;
  white-space: nowrap;
  background: white;
  color: #333;
  top: 100%;
  box-shadow: 8px 5px 6px 0px #00000020;
  right: 0;
}

.user-listing li:not(:last-child) {
  margin-bottom: 1rem;
}

.user-listing li svg {
  margin-right: 0.5rem;
  transform: scale(1.2) translateY(2px);
}

.social-login {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.loginButton {
  width: 150px;
  padding: 15px 25px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 150ms ease-in;
}

.loginButton.mobile {
  width: 120px;
  padding: 15px 10px;
}

.google{
  background-image: linear-gradient(to right, #df4930 0 50%, #e08c7d 50% 100% );
  background-size: 200% 100%;
}

.facebook{
  background-color: #507cc0;
  background-image: linear-gradient(to right, #507cc0 0 50%, #94a6c0 50% 100% );
  background-size: 200% 100%;
}

.google:hover, .facebook:hover {
  background-position: 100% 0;
}

.icon{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.vrib-icon {
  color: var(--vrib-color);
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.move-modal {
  transform: rotateY(90deg);
  transform-origin: left;
}

.forgot-helper {
  text-align: center;
}

.profile-page {
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  grid-gap: 1rem;
}

.profile-header {
  margin: 2rem 0;
}

.profile-input-field input, .profile-input-field select {
  margin-top: 0.5rem;
  padding: 0.5rem;
  -webkit-appearance: none;
}

.profile-page.mobile-page {
  padding: 1rem;
  grid-template-columns: 1fr;
}

.profile-menu {
  background: var(--vrib-color);
  margin: 0;
  padding: 1rem 2rem;
  height: 100%;
}

.profile-overview {
  display: grid;
  justify-content: center;
  grid-gap: 1rem;
}

.profile-overview .avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  justify-self: center;
}

.subscription-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.subscription-details {
  display: flex;
  align-items: center;
}

#calendar {
  margin: 1rem 0;
}

#calendar h1 {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  padding: 1rem 0;
}

.week-arrow {
  font-size: 2rem;
  color: #ddd;
  transition: all 150ms ease-in;
}

.week-arrow:hover {
  color: var(--vrib-color);
}

.week-modifiers {
  display: grid;
  grid-template-columns: 10% 1fr 10%;
}

.week-modifiers > div:first-child {
  justify-self: flex-start;
  cursor: pointer;
}

.week-modifiers > div:nth-child(2) {
  justify-self: center;
  text-transform: uppercase;
}

.week-modifiers > div:last-child {
  justify-self: flex-end;
  cursor: pointer;
}

.calendar-grid {
  display: grid;
  /*grid-template-columns: repeat(8, 1fr);*/
  grid-template-columns: repeat(8, minmax(35px, 1fr));
  min-height: 80px;
  background: #eeeeee45;
  grid-gap: 0.5rem;
}

.calendar-mobile .calendar-grid {
  grid-gap: 5px;
  font-size: 9px;
}

.calendar-griditem {
  height: 100%;
  background: #ddd;
  display: grid;
  justify-content: center;
  word-break: break-all;
  padding: 3px;
  position: relative;
}

.calendar-griditem > div {
  text-align: center;
  z-index: 9;
}

.calendar-gridtitle {
  font-weight: bold;
}

.calendar-griditem-price {
  align-self: center;
}

.booking-start, .booking-other, .booking-finish {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.booking-other::before {
  display: block;
  content: '';
  background: lightblue;
  position: absolute;
  height: 100%;
  width: 100%;
}

.booking-start::after, .booking-finish::before {
  display: block;
  content: '';
  background: linear-gradient(-60deg, lightblue 50%, transparent 50%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.booking-finish::before {
  background: linear-gradient(120deg, lightblue 50%, transparent 50%);
}

.booking-other.external-booking::before {
  background-color: lightcoral;
}

.booking-start.external-booking::after {
  background: linear-gradient(-60deg, lightcoral 50%, transparent 50%);
}

.booking-finish.external-booking::before {
  background: linear-gradient(120deg, lightcoral 50%, transparent 50%);
}

.highlighted-color {
  background: var(--vrib-color);
}

.highlighted-color div {
  color: white;
}

.calendar-booking {
  text-align: center;
  padding: 0.25rem;
  text-decoration: none;
  color: #333;
  border: 2px solid var(--vrib-color);
  margin: 0.25rem;
  background: #b1b1ea;
  border-radius: 6px;
  transition: all 200ms ease-in-out;
  z-index: 5;
  font-size: 0.65rem;
}

.calendar-booking:hover {
  outline: 2px solid var(--vrib-color);
  font-weight: bold;
}

.modal-window {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close::before, .modal-close::after {
  content: '';
  background-color: #aaa;
  width: 3px;
  height: 100%;
  transform: rotate(45deg);
  position: absolute;
  transition: background-color 150ms ease-in;
}

.modal-close::after {
  transform: rotate(-45deg);
}

.modal-close:hover::before, .modal-close:hover::after {
  background-color: var(--vrib-color);
}

.modal-form, .modal-errors {
  background-color: white;
  padding: 3rem;
  border-radius: 1rem;
  position: relative;
  justify-self: center;
  align-self: center;
}

.modal-close {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 9;
}

.modal-form form > div:not(:last-child) {
  margin-bottom: 2rem;
}

.modal-form form label {
  display: block;
}

.modal-form form input, .modal-form form button {
  width: 100%;
  padding: 0.25rem;
}

.modal-form form button {
  width: 100%;
}

.modal-window iframe {
  width: 400px;
  height: 500px;
  overflow: hidden;
  background-color: white;
  border-radius: 1rem;
}

.calendar-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 3px;
  padding: 2rem;
}

.calendar-holder {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 300px;
  box-shadow: 2px 4px 24px 12px #00000020;
}

.calendar-days {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.inactive-days {
  color: #eee;
  cursor: not-allowed;
}

.hidden-days {
  opacity: 0;
}

#xendit-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 400px;
  grid-gap: 10px;
}

#xendit-form > *:first-child, #xendit-form > *:last-child {
  grid-column: 1 / -1;
}

#xendit-form .form-input {
  position: relative;
}

#xendit-form .input-error {
  color: darkred;
  font-size: 0.65rem;
  position: absolute;
  top: 100%;
  background-color: white;
  border: 1px solid darkred;
  padding: 0.25rem;
}

#xendit-form .error-border {
  background-color: pink;
}

.expiration-date {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  position: relative;
}

.content-basic {
  max-width: 1320px;
  margin: 0 auto;
}

.content-double {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 1rem;
}

.booking-form {
  position: sticky;
  top: 120px;
  padding: 1rem;
  background-color: white;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,.3);
  margin-bottom: 2rem;
}

.booking-form h2 {
  font-weight: bold;
  font-size: 1.15rem;
  margin-bottom: 1rem;
}

#booking-form {
  margin-bottom: 1rem;
}

.calendar-holder {
  position: absolute;
  background-color: white;
  width: min-content;
  z-index: 9;
}

.month-holder h2 {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  flex-grow: 1;
}

.calendar-weekdays {
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.month-title {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.month-title span {
  font-size: 24px;
  cursor: pointer;
  color: var(--vrib-color);
}

.content-double aside {
  margin-right: 2rem;
}

.price-tag {
  background: var(--vrib-color);
  padding: 1rem;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.booking-form .input-holder {
  position: relative;
  margin-bottom: 1rem;
}

.booking-form .input-holder .input-error {
  position: absolute;
  background-color: white;
  padding: 0.25rem;
  color: darkred;
  border: 1px solid darkred;
  top: calc(100% + 3px);
}

.booking-form input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.booking-form button {
  background-color: var(--vrib-color);
  color: white;
  border: none;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 150ms ease-in;
  border-radius: 5px;
}


.booking-form button:hover {
  font-weight: bold;
  background-color: #8b8bd2;
}

.form-priceinfo div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}

.images-holder {
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  grid-template-rows: repeat(2, 1fr);
  max-height: 515px;
  grid-gap: 1px;
}

.image-holder.main-image {
  grid-column: 1 / 3;
  grid-row: 1 / -1;
}

.image-holder {
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
}

.image-holder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}

.image-overlay:hover {
  background-color: #8b8bd256;
}

.modal-frame {
  position: relative;
  display: flex;
  align-items: center;
  max-height: 100vh;
  padding: 1rem;
}

.modal-arrow {
  font-size: 26px;
  color: white;
  cursor: pointer;
  transition: all 150ms ease-in;
}

.modal-arrow:hover {
  color: var(--vrib-color);
}

.modal-image {
  flex-grow: 1;
  margin: 1rem;
}

.no-scroll {
  overflow: hidden;
}

.highlighted-features {
  display: flex;
  grid-gap: 20px;
  max-width: 500px;
  color: #484848;
  margin-bottom: 3rem;
}


.highlighted-features > div {
  display: flex;
  align-items: center;
}

.highlighted-features svg {
  font-size: 1.15rem;
  margin-right: 0.635rem;
  color: var(--vrib-color);
}

h1.listing-title {
  font-size: 24px;
  color: #484848;
  line-height: 1.3;
  margin: 0px 15px 15px 0px;
  font-weight: 600;
}

h2 {
  line-height: 1.3;
  font-size: 1rem;
  color: #484848;
  font-weight: 600;
  margin-bottom: 1rem;
}

.description h2 {
  font-weight: bold;
  color: var(--vrib-color);
  font-size: 1.5rem;
  width: max-content;
}

p {
  color: #2d2d2d;
  font-size: 1rem;
  line-height: 1.75rem;
}

.description-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.description-data p, .description-data svg {
  margin-bottom: 1rem;
}

.description-data .price-tags {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.description-data .price-tags p:last-child {
  font-weight: bold;
}

.description {
  margin-bottom: 3rem;
}

.description-details {
  display: flex;
  align-items: center;
}

.description-details svg {
  font-size: 20px;
  color: var(--vrib-color);
  margin-right: 6px;
}

.profile-menu-holder {
  position: sticky;
  top: 100px;
}

.profile-overview h2 {
  color: white;
}

.profile-menu-holder a, 
.profile-menu-holder li {
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: all 150ms ease-in;
}

.profile-menu-holder a:hover, 
.profile-menu-holder li:hover, .user-listing a:hover, .user-listing li:hover {
  color: var(--vrib-pink);
}
 
.profile-menu-holder li:not(:last-child) {
  margin-bottom: 0.65rem;
}

.user-listing a, .user-listing li {
  color: #484848;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  transition: all 150ms ease-in;
}

.user-listing a svg, .user-listing li svg {
  transition: all 150ms ease-in;
}

.user-listing a:hover svg, .user-listing li:hover svg {
  transform: scale(1.5) translateY(2px);
}

.message-holder {
  margin-bottom: 3rem;
}

.message-head {
  font-weight: bold;
}

.message-title .title {
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--vrib-color);
  width: max-content;
  padding-bottom: 0.5rem;
}

.message-content {
  padding: 2rem;
  box-shadow: 1px 1px 5px 3px inset #00000010;
  border-radius: 1rem;
  position: relative;
}

.message-holder .title {
  cursor: pointer;
  transition: all 50ms ease-in;
  padding: 0.25rem;
}

.message-holder .title:hover {
  box-shadow: 0 -3rem inset var(--vrib-color);
  color: white;
}

.message-reply button {
  background: var(--vrib-color);
  border: none;
  padding: 0.5rem 1rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 150ms ease-in;
}

.message-reply textarea {
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  outline: none;
  resize: none;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
  box-sizing: border-box;
}

.message-reply button:hover {
  background-color: #a1a1db; 
}

input, select, button.filter-button, textarea {
  background: #fafafc;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  color: #333; 
  font-family: 'Work Sans', sans-serif;
  line-height: 1.3;
  font-size: 14px;
}

.sort-button {
  appearance: none;
  border: none;
  border-radius: 1rem;
  color: var(--vrib-color);
  background: transparent;
  font-size: 1.25rem;
  padding: 0.635rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.sort-button:hover {
  background-color: var(--vrib-pink);
}

.filter-element {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-top: 2rem;
}

button.filter-button {
  justify-self: flex-end;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

button.filter-button > * {
  color: var(--vrib-color);
  font-weight: 600;
}

button.filter-button.button-off {
  opacity: 0;
  height: 0;
  padding: 0 1.5rem;
}

.filter-input {
  position: relative;
}

.filter-input .profile-input-field input, .filter-input .profile-input-field select {
  background: #fff;
}

.filter-input .profile-input-field input[type=submit] {
  background-color: var(--vrib-color);
}

input[type=radio], input[type=checkbox] {
  width: initial;
}

.filter-input-fieldset {
  display: flex;
  justify-content: space-between;
  margin: 0 0.635rem;
}

.filter-input-fieldset .profile-input-field {
  display: flex;
  grid-gap: 0.5rem;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.filter-input-fieldset .profile-input-field label {
  font-weight: 600;
  color: #333;
}

input:disabled {
  color: #aaa;
}

.listing-part input[type=checkbox] {
  width: auto;
}

.part-input.input-details {
  border-radius: 1rem;
  padding: 1rem;
  background-color: #ededed;
  position: relative;
}

.part-input.input-details::before {
  width: 3rem;
  height: 3rem;
  background-color: #ededed;
  content: '';
  display: block;
  position: absolute;
  right: 25%;
  top: -1rem;
  transform: rotate(45deg);
}

input[type=checkbox] {
  border-radius: 10px;
  margin: 0;
  border: 1px solid var(--vrib-grey);
  height: 20px;
  font-size: 20px;
  width: 20px;
  appearance: unset;
  position: relative;
}

input[type=checkbox]:checked {
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type=checkbox]:checked::after {
  content: '\2713';
  position: absolute;
  color: var(--vrib-color);
  font-weight: bold;
  transform: scale(1.4) translate(2px, -1px);
}

.listing-part input#title, .listing-part textarea {
  width: 100%;
}

.listing-part textarea {
  resize: none;
}

.listing-part {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
  grid-gap: 1rem;
}

.listing-part h3, .checklist-holder h3, .listing-info h3, .listing-subtitle {
  font-size: 16px;
  font-weight: bold;
  color: #878F9D;
}

label {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 14px;
  color: #878F9D;
}

.listing-part label sup {
  font-size: 0.4rem;
  position: absolute;
}

.listing-part input:focus-visible, .listing-part textarea:focus-visible, .listing-part select:focus {
  outline: 2px solid var(--vrib-color);
}

h1.listing-title {
  font-size: 18px;
  color: #333;
  width: bolder;
  padding: 1rem 0;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--vrib-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--vrib-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.submit-button, .vrib-button {
  padding: 0.75rem 0.5rem;
  border-radius: 0.5rem;
}

.submit-button:disabled, .vrib-button:disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #eee;
}

.profile-menu-holder .sub-menu {
  padding-left: 1rem;
}

.profile-menu-holder .active-item {
  font-weight: bold;
}

.dropzone {
  border: 2px dashed var(--vrib-light);
  background-color: #fafafc;
  border-radius: 1rem;
  width: 100%;
  padding: 3rem;
  margin: 3rem auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}

.dropzone p {
  text-align: center;
}

.image-handler {
  height: 200px;
  width: 200px;
  overflow: hidden;
  top: 0;
  left: 0;
}

.image-handler img {
  width: 100%;
}

button.delete-button {
  position: relative;
  border: none;
  color: white;
  background-color: var(--vrib-color);
  padding: 0.25rem 0.5rem;
  font-size: 23px;
  cursor: pointer;
  font-weight: bold;
}

button.delete-button:hover {
  background-color: #a1a1db; 
}

.dropzone-disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

.listing-part .input-error {
  outline: 2px solid rgb(179, 16, 16);
  background-color: pink;
}

.part-input {
  grid-column: 2 / -1;
}

#longterm .part-input, #longterm .listing-part button, #shortterm .part-input, #shortterm .listing-part button {
  grid-column: 1 / -1;
}

.bedroom-variants {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.bedroom-variants h4 {
  grid-column: 1 / -1;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--vrib-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checklist-holder {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
}

.checklist-parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.checklist-element {
  margin-bottom: 0.75rem;
}

.checklist-element label {
  color: #878F9D;
}

.ical-link {
  display: inline;
  cursor: pointer;
  font-weight: bold;
}

.ical-holder {
  position: relative;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.ical-popup {
  position: absolute;
  padding: 0.65rem 1rem;
  border-radius: 10px;
  background-color: #eee;
  border: 1px solid #333;
  font-size: 0.85rem;
  font-weight: bold;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loader-container {
  display: grid;
  justify-content: center;
  align-content: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--vrib-color);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.modal-options h2 {
  cursor: pointer;
}

.modal-options h2:hover {
  color: #a1a1db;
}

.listing-image {
  width: 200px;
  overflow: hidden;
  max-height: 130px;
}

.listing-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listing-holder {
  display: flex;
  grid-gap: 2rem;
  padding: 1rem;
  background-color: #eeeeee50;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.listing-holder.higlighted-holder {
  background-color: #b1b1ea;
}

.listing-info {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.listing-info h2 {
  margin: 0;
}

.listing-settings {
  justify-self: flex-end;
  display: flex;
  grid-gap: 0.75rem;
}

.listing-settings > *, .listing-info a {
  color: #333;
  transition: all 150ms ease-in;
  text-decoration: none;
  cursor: pointer;
}

.listing-settings > * {
  font-size: 20px;
}

.listing-settings > *:hover, .listing-info a:hover {
  color: var(--vrib-color);
}

.standalone-form {
  max-width: 600px;
  margin: 2rem auto;
}

.standalone-form > * {
  margin-bottom: 1rem;
}

.form-field {
  margin-bottom: 1rem;
}

.standalone-form .submit-button {
  width: 100%;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  line-height: 1.3;
}

.standalone-form .submit-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.full-width {
  width: 100%;
}

.text-link {
  cursor: pointer;
  color: var(--vrib-color); 
  transition: all 100ms ease-in;
}

.nodecor-text {
  text-decoration: none;
}

.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background: #ffffff70;
}

.title-area {
  display: flex;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.title-area img {
  width: 100%;
  flex: 4;
}

.title-area .title-text {
  flex: 3;
}

.title-area .title-text time {
  color: var(--vrib-color);
  display: inline-block;
  padding: 1rem 0;
  border-top: 2px solid var(--vrib-color);
}

.title-area .title-text button {
  display: block;
}

.main-frame .back-arrow, .hide-booking.back-arrow {
  margin-bottom: 2rem;
}

.hide-booking.back-arrow {
  font-size: 2rem;
  color: #ddd;
  cursor: pointer;
}

.main-frame svg.animated {
  font-size: 2.25rem;
  color: #ddd;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.main-frame svg.animated:hover   {
  color: var(--vrib-color);
  animation: moving 500ms linear infinite; 
}

@keyframes moving {
  0% {transform: translateX(0);}
  50% {transform: translateX(-0.5rem); }
  100% {transform: translateX(0)}
}

/* DESTINATIONS PAGE */

.highlighted-textbox {
  background-color: #6666E11C;
  padding: 2.5rem;
}

.highlighted-textbox p {
  font-size: 1rem;
}

.highlighted-textbox p strong {
  font-weight: bold;
}

.main-frame h2.villa-suggestion {
  color: var(--vrib-color);
  margin-top: 2rem;
  font-size: 1.75rem;
}

button.vrib-button {
  margin: 2rem auto;
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
}

.grey-frame {
  background-color: #6666E11C;
  padding: 2rem 0;
}

.tips-frame {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.tips-frame .tips-icon {
  flex: 0;
  font-size: 4rem;
  margin-right: 3rem;
  color: var(--vrib-color);
}

.tips-frame .tips-content {
  flex: 4;
}

.tips-frame .tips-content .tips-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.tips-frame .tips-content .tips-description {
  font-size: 1rem;
}

.vrib-button.finish-button {
  background-color: green;
  padding-left: 2rem;
  padding-right: 2rem;
  transition: background-color 200ms ease-in-out;
}

.vrib-button.finish-button:hover {
  background-color: #009f10;
}

/* HOME PAGE */

.align-middle {
  text-align: center;
}

h2.part-title {
  color: var(--vrib-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.align-middle p {
  font-size: 1.05rem;
  font-weight: bold;
  color: #333;
}

.desc-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

.desc-card {
  box-shadow: 1px 3px 6px #00000020;
  background-color: white;
  padding: 2rem 3rem;
  display: grid;
  justify-items: center;
  text-align: center;
}

.desc-card h3 {
  line-height: 1.4;
  margin: 1rem 0;
}

.desc-card .card-icon {
  color: var(--vrib-color);
  font-size: 2.5rem;
}

.destination-cards {
  display: flex;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.destination-card {
  height: 300px;
  display: flex;
  background-color: #00000010;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.destination-card .destination-image::before {
  transition: all 400ms ease-in-out;
  transform: translateX(-100%);
  transform-origin: left;
  opacity: 0.5;
  background-color: var(--vrib-color);
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 998;
}

.destination-card:hover .destination-image::before {
  transform: translateX(0);
}

.destination-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.destination-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.destination-card::before {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent 0, #00000050 100%);
  position: absolute;
  z-index: 9;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.destination-text {
  z-index: 999;
  padding: 1rem;
  position: relative;
}

.destination-card .destination-text::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 400ms ease-in-out;
  transform: translateX(100%);
  transform-origin: right;
  background: var(--vrib-pink);
  z-index: 990;
}

.destination-card:hover .destination-text::before {
  transform: translateX(-1rem);
}

.destination-card h3 {
  margin-top: 1rem;
}

.destination-card h3, .destination-card p {
  color: white;
  position: relative;
  z-index: 999;
}

.fifty {
  flex: 5;
}

.seventy {
  flex: 7;
}

.thirty {
  flex: 3;
}

.cta-large {
  font-size: 3rem;
  color: white;
  font-weight: 400;
  text-align: center;
}

.cta-content {
  font-size: 2rem;
  color: white;
  font-weight: 400;
  text-align: center;
}

.cta-divider {
  display: flex;
  justify-content: center;
  grid-gap: 3rem;
  align-items: center;
  margin: 2rem 0;
}

.cta-divider .divider {
  border-bottom: 2px solid var(--vrib-color);
  width: 200px;
}

.cta-image {
  height: 120px;
  display: block;
}

.vrib-button.cta {
  padding: 0.75rem 1.25rem;
}

.header-back h1, .header-back p, .header-back button {
  z-index: 10;
}

.header-back::before {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #0002 0, #00000090 100%);
  position: absolute;
  z-index: 9;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* MAPS PART */

.map-infobox {
  font-weight: bold;
  margin-right: 0.25rem;
  padding-bottom: 0;
  border-bottom: 1px solid var(--vrib-pink);
  font-size: 0.85rem;
}

.infowindow-holder a {
  text-decoration: none;
  color: inherit;
}

.infowindow-overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(white 0%, transparent 100%);
  position: absolute;
  opacity: 0.65;
}

.infowindow-image {
  position: relative;
}

/* NAVBAR */

.dropdown-destination {
  left: -1rem;
  top: 100%;
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  align-items: flex-start;
}


/* HOST SUB */

.bookings-grid {
  display: grid;
  max-width: 1320px;
  margin: 0 auto;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;  
}

.upcoming-booking {
  margin: 2rem;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px 3px 6px #00000020;
  border-radius: 1.5rem;
}

.booking-filters {
  display: flex;
  grid-gap: 2rem;
  max-width: 1320px;
  margin: 0 auto;
}

.interval-selector {
  padding: 1rem;
  border-radius: 1.5rem;
  background: white;
  box-shadow: 1px 3px 6px #00000020;
  margin: 2rem;
  cursor: pointer;
}

.interval-selector.active-selector {
  background: var(--vrib-color);
  color: white;
}

.button-group {
  display: flex;
  grid-gap: 1rem;
  justify-content: center;
  margin: 0 auto;
}

.button-group .vrib-button {
  margin: unset;
}

.button-group .proceed-button {
  background-color: #ce0000;
}

.button-group .proceed-button:hover {
  background-color: #ff7c7c;
}

/* PAYMENT FLOW MODAL */

.payment-flow form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.payment-flow form .profile-input-field {
  margin-bottom: 1rem;
}

.payment-flow form .profile-input-field:last-child {
  grid-column: 1 / -1;
}

.form-input {
  display: flex;
  flex-wrap: wrap;
}

.form-input label {
  flex-basis: 100%;
}

.booking-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.booking-image {
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

/* GUEST NUMBER MODAL */

.guesttype-holder {
  display: grid;
  grid-template-columns: 55% 1fr;
  grid-gap: 1rem;
}

.guestno-holder {
  position: absolute;
  background: white;
  padding: 2rem;
  box-shadow: 2px 4px 24px 12px #00000020;
  min-width: max-content;
}

.counter-holder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 0.25rem;
}

.counter-button {
  border: 1px solid #999;
  border-radius: 50%;
  padding: 1rem;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  color: #666;
}

.counter-button:hover {
  color: #111;
  border: 1px solid #333;
}

.counter-disabled {
  cursor: not-allowed;
  color: #ccc;
  border: 1px solid #ddd;
}

.counter-disabled:hover {
  color: #ccc;
  border: 1px solid #ddd;
}

/* LONG TERM LANDING PAGE */

.longterm-case {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.longterm-images .thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
}

.longterm-case .highlighted-textbox h2 {
  color: var(--vrib-color);
  font-size: 1.25rem;
  margin: 1rem auto;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.image-hero {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
}

.longterm-case .highlighted-textbox {
  border-radius: 1rem;
  opacity: 0.5;
  transition: opacity 250ms ease-in-out;
}

.longterm-case .highlighted-textbox:hover {
  opacity: 1;
}

.shortterm-frame {
  position: relative;
}

.shortterm-frame > * {
  z-index: 99;
  position: relative;
}


.shortterm-frame::before {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #0003;
  left: 0;
  right: 0;
  z-index: 9;
  content: '';
}

.advantages-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.advantages-list li {
  background: #6666E11C;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 1px 3px 6px #0001;
}

.advantage-listitem {
  transition: transform 0.5s ease-in-out 2s;
}

.advantages-list .advantage-listitem:nth-of-type(2n) {
  transform: translateY(-30px);
}

.advantages-list li:hover svg {
  animation: longterm 500ms linear forwards; 
}

@keyframes longterm {
  0%  { transform: rotateY(0deg) scale(1) }
  50% { transform: rotateY(180deg) scale(1.2) }
  100% { transform: rotateY(360deg) scale(1) }
}

.advantages-list .icon {
  width: 100%;
  display: grid;
  justify-content: flex-start;
}

.advantages-list .icon::after {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  background-color: var(--vrib-pink);
  margin-top: 10px;
}

.advantages-list li p { 
  color: #333;
  font-size: 1rem;
}

.advantages-list li p.title {
  font-weight: bold;
  margin-top: 3rem;
}

.process-list {
  list-style-position: inside;
}

.process-list li {
  background: var(--vrib-color);
  margin-bottom: 2rem;
  padding: 1.5rem;
  color: white;
  font-weight: bold;
  border-radius: 0.75rem;
}

.process-list li::marker {
  content: "+ ";
}

.thumbnail-holder:hover {
  opacity: 0.6;
}

.thumbnail-holder {
  position: relative;
}

.thumbnail-holder::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--vrib-color);
  opacity: 0.2;
}

.hero-text h3 {
  padding: 2rem;
  background-color: white;
  transform: translateX(-8rem);
  box-shadow: 1px 6px 12px 0 #00000020;
  border-radius: 0.5rem;
  font-size: 3.5rem;
  line-height: 4rem;
}

/* inquiry popup */

textarea#inquiry {
  resize: none;
  background: #fafafc;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #333;
  font-family: 'Work Sans', sans-serif;
  line-height: 1.3;
  font-size: 14px;
}

.listing-window {
  border: 1px solid #eee;
}

.details-frame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fafafc;
  padding: 1rem 0;
}

.listing-params {
  display: flex;
  justify-content: space-between;
  margin: 0px 1rem;
  align-items: center;
}

.details-frame .listing-price {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}

.details-frame .listing-price {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}

.details-frame .listing-price p {
  font-size: 0.9rem;
  color: var(--vrib-grey);
  margin-bottom: 0;
}

.input-fieldset {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
}

.input-fieldset .input-field {
  width: 100%;
}

/* ADMIN PAGE */

.alternating-list > div {
  display: grid;
  align-items: center;
}

.alternating-list:nth-of-type(2n-1) {
  background: var(--vrib-light);
}

/* MOBILE FROM HERE */

.mobile-navbar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 1rem;
  padding-right: 1rem;
}

.mobile-navbar > a {
  background: white;
}

.mobile-navbar .menu-items .submenu {
  margin-left: 1rem;
  font-weight: 400;
}

.menu-burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: flex-end;
}

.menu-burger, .mobile-navbar a {
  z-index: 99;
}

.menu-burger span:not(:last-of-type) {
  margin-bottom: 6px;
}

.menu-burger span {
  width: 24px;
  height: 3px;
  background-color: #ccc;
  transition: all 200ms ease-in;
}

.menu-burger:hover span {
  background-color: var(--vrib-color);
}

.mobile-navbar .menu-items {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  padding-top: 7rem;
  position: fixed;
  top: 0;
  bottom: 0;
  background: white;
  left: 0;
  right: 0;
  overflow-y: scroll;
  z-index: 9;
  padding-left: 1rem;
}

.mobile-navbar .menu-items a, .mobile-navbar .menu-items span {
  color: #333;
  text-decoration: none;
  transition: all 100ms ease-in;
  padding: 0 3px;
  font-weight: bold;
  cursor: pointer;
}

.mobile-navbar .menu-items > * {
  margin-bottom: 2rem;
}

.mobile-navbar .menu-items a:hover, .mobile-navbar .menu-items span:hover {
  color: var(--vrib-color);
}

.mobile-navbar .user-dropdown {
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: none;
  bottom: 0;
  position: relative;
}

.mobile-navbar .slide-element {
  height: 100vh;
  position: fixed;
  transition: all 200ms ease-in-out;
  transform: translateX(calc(-100% - 1rem));
  left: 0;
  background-color: #fff;
  bottom: 0;
  top: 0;
  padding-top: 7rem;
  padding-bottom: 2rem;
  right: 0;
  z-index: 100;
  padding-left: 1rem;
  overflow-x: scroll;
}

.mobile-navbar .slide-element .user-listing li {
  margin-bottom: 2rem;
}


.mobile-navbar .slide-element .user-listing li, .mobile-navbar .slide-element .user-listing a {
  font-size: 1rem;
}

.mobile-navbar .slide-element span svg {
  margin-right: 0.5rem;
  transform: scale(1.2) translateY(2px);
}

.mobile-navbar .slide-in.slide-element {
  transform: translateX(0);
}

.booking-mobile {
  position: fixed;
  left: 0;
  top: 25%;
  z-index: 99;
  background-color: var(--vrib-color);
  padding: 1rem;
  border-radius: 0 2rem 2rem 0;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 1px 3px #666;
}

.mobile-calendar .calendar-days {
  width: unset;
}

.modal-with-header {
  padding: 0;
  overflow: hidden;
  min-width: 24rem;
}

.modal-with-header .modal-close::before, .modal-with-header .modal-close::after {
  background-color: white;
}

.modal-header::after {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  background: #59d19a;
  height: 20px;
  top: calc(100% - 10px);
  transform: rotate(45deg);
  left: calc(50% - 9px);
}

.modal-header.modal-red::after {
  background-color: var(--vrib-red);
}

.modal-header.modal-vrib, .modal-header.modal-vrib::after {
  background-color: var(--vrib-color);
}

.modal-header {
  position: relative;
  background-color: #59d19a;
  padding: 1.635rem;
}

.modal-header.modal-red {
  background-color: var(--vrib-red);
}

.modal-header h1 {
  text-align: center;
  color: white;
}

.modal-icon svg {
  position: absolute;
  height: 100%;
  left: 0.635rem;
  top: 0.635rem;
  fill: white;
}

.modal-body {
  padding: 2rem;
}

.modal-body p {
  color: var(--vrib-color);
  font-size: 1.05rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  text-align: center;
}

.modal-body .vrib-button {
  padding: 0.5rem 2.25rem;
  font-size: 0.75rem;
  margin: 0 auto;
}

.upload-process {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 37.5rem;
  margin-top: 2rem;
  border: 1px solid #484554;
  padding: 1rem;
  border-radius: 2rem;
}

.upload-element {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
}

.upload-element label {
  margin-bottom: 0;
}

.upload-element input[type=checkbox] {
  border: 2px solid #FF716F;
}

.upload-element input[type=checkbox]:checked {
  border: 2px solid #59D19A;
  background-color: #59D19A;
}

.upload-element input[type=checkbox]:checked::after {
  color: white;
  transform: scale(1);
}

@media screen and (max-width: 1320px) {
  .navbar-elements, .footer, .cards-grid {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1020px) {
  .navbar-center {
    margin-left: 1rem;
  }

  .navbar-center > *:not(:last-child) {
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 1320px) and (min-width: 861px) {
  .header-back > div:last-child, .main-frame {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 860px) {
  .footer-elements {
    grid-template-columns: 1fr;
  }

  .listing-holder {
    flex-direction: column;
    grid-gap: 1rem;
    overflow: hidden;
    padding: 0;
  }

  .listing-info {
    grid-template-columns: 90% 1fr;
    padding: 0 1rem;
    grid-template-rows: repeat(4, 1fr);
    margin-bottom: 1rem;
  }

  .listing-info > *:not(:last-child) {
    grid-column: 1 / 2;
  }

  .listing-info .listing-settings {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    flex-direction: column;
  }

  .listing-image {
    width: 100%;
  }

  .listing-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .main-frame {
    padding: 0 1rem;
  }

  .news-grid {
    grid-template-columns: 1fr;
  }

  .title-area {
    flex-direction: column-reverse;
  }

  .tips-frame .tips-icon {
    margin-right: 1.5rem;
  }

  .cards-grid {
    grid-template-columns: 1fr;
  }

  .header-back h1 {
    font-size: 3rem;
    padding: 0;
  }

  #login-modal {
    padding: 3rem 2rem;
  }

  .header-context {
    min-height: calc(100vh - 5rem);
  }

  .search-bar {
    position: relative;
    bottom: unset;
  }

  .search-bar form {
    flex-direction: column;
    grid-gap: 1rem;
  }

  .search-bar form select {
    width: 100%;
  }

  .search-bar .submit-button {
    padding: 0.5rem;
  }

  .main-content .main-frame {
    margin-top: 1rem;
  }

  .desc-cards {
    grid-template-columns: 1fr;
  }

  .destination-cards {
    flex-direction: column;
  }

  .destination-cards .destination-card {
    flex: 1;
    padding: 5rem 1rem;
  }

  .destination-card p, .destination-card h3 {
    transform: translateY(4rem);
  }

  .header-back {
    min-height: 60vh;
  }

  .tips-frame {
    flex-direction: column;
    grid-gap: 2rem;
  }

  .content-basic main {
    padding: 1rem;
  }

  .content-basic aside {
    position: fixed;
    background-color: white;
    padding: 1rem;
    width: 100%;
    transition: transform 200ms ease-in-out;
    transform: translateX(-100%);
    top: 5rem;
    bottom: 0;
    z-index: 10;
  }

  .description-data {
    grid-template-columns: 1fr;
  }

  .content-double {
    grid-template-columns: 1fr;
  }

  .longterm-case {
    grid-template-columns: 1fr;
  }

  .image-hero {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero-text h3 {
    transform: none;
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .advantages-list {
    grid-template-columns: 1fr;
  }

  .advantages-list li {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
  }

  .advantages-list li p {
    margin-top: 0;
  }

  .longterm-case .highlighted-textbox:not(:first-of-type) {
    opacity: 1;
  }

  .advantages-list .advantage-listitem {
    flex-direction: column;
  }

  .advantages-list .advantage-listitem:nth-of-type(2n) {
    transform: translateY(0);
  }

  .destination-card .destination-text::before {
    content: none;
  }
}

form div.input-field > span {
  position: relative;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--vrib-red);
}